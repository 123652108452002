export default {
  text: '#010101',
  primary: '#D0BD79',
  secondary: '#D0BD79',
  tertiary: '#D0BD79',
  background: '#FFFFFF',
  backgroundSecondary: '#ffffff',
  light: '#EFEFEF',
  dark: '#010101'
}
