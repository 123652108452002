export default {
  heading: 'Playfair Display, serif',
  body: 'Open Sans, serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  display2: 'Playfair Display',
  display3: 'Playfair Display',
  googleFonts: ['Open Sans:400,500,700', 'Playfair Display:400,500']
  // customFamilies: [''],
  // customUrls: [''],
}
